var render = function render(){var _vm=this,_c=_vm._self._c;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),_c('div',{staticClass:"row",attrs:{"id":"expert"}},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('div',{staticClass:"row align-items-center mb-4"},[_c('div',{staticClass:"col-md-6"})]),_c('div',{staticClass:"table-responsive mb-0"},[_c('b-table',{key:_vm.key,staticClass:"table-bordered table-hover",attrs:{"striped":"","bordered":"","items":_vm.editTableData,"per-page":0,"current-page":_vm.currentPage,"fields":_vm.editFields,"sort-by":_vm.sortBy,"sort-desc":_vm.sortDesc,"filter-included-fields":_vm.filterOn,"responsive":"sm"},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"filtered":_vm.onFiltered},scopedSlots:_vm._u([{key:"cell(name)",fn:function(row){return [_c('span',{staticClass:"text-center"},[(
                      (row.item.first_name != null &&
                        row.item.first_name != '') ||
                      (row.item.last_name != null && row.item.last_name != '')
                    )?[_c('span',{staticClass:"email-word-break"},[_vm._v(_vm._s(row.item.first_name)+" "+_vm._s(row.item.last_name))])]:(
                      row.item.first_name == 'null' ||
                      row.item.last_name == 'null'
                    )?[_c('span',{staticClass:"email-word-break"},[_vm._v("---")])]:(
                      row.item.first_name == '' || row.item.last_name == ''
                    )?[_c('span',[_vm._v("---")])]:(
                      row.item.first_name == null ||
                      row.item.last_name == null
                    )?[_c('span',[_vm._v("---")])]:[_c('span',[_vm._v("---")])]],2)]}},{key:"cell(city)",fn:function(row){return [(row.item.city)?_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(row.item.city))]):_c('span',[_vm._v("---")])]}},{key:"cell(country)",fn:function(row){return [(row.item.country)?_c('span',{staticClass:"text-center"},[_vm._v(_vm._s(row.item.country))]):_c('span',[_vm._v("---")])]}},{key:"cell(edit)",fn:function(row){return [_c('router-link',{attrs:{"to":{ name: 'edit-plans', params: { id: row.item.id } }}},[_c('i',{staticClass:"fa fa-edit edit-i"})])]}}])})],1)])])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }