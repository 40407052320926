import { members } from "../../js/path";

export default {
  data() {
    return {
      pageNo: null,
      submitted: false,
      status: false,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,
      tableData: [],
      editTableData: [],
      currentPage: 1,
      params: "",
      key: 0,
      editFields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Member Name",
          class: "w-300",
        },
        {
          key: "speciality",
          label: "Speciality",
          class: "w-300",
        },
        {
          key: "mobile_number",
          label: "Mobile No.",
        },
        {
          key: "email",
        },
        {
          key: "city",
          label: "City",
        },
        {
          key: "country",
          label: "Country",
        },
      ],
      fields: [
        {
          key: "id",
          label: "ID",
          sortable: true,
        },
        {
          key: "name",
          label: "Member Name",
          class: "w-300",
        },
        {
          key: "speciality",
          label: "Speciality",
          class: "w-300",
        },
        {
          key: "city",
          label: "City",
        },
        {
          key: "country",
          label: "Country",
        },
        {
          key: "edit",
        },
      ],
      form: {
        password: "",
      },
      showModal: false,
      modalItem: null,
      errorMessage: false,
      showPasswordModal: false,
      passwordMatch: "",
      modalPassword: "",
    };
  },
  methods: {
    searchFor() {
      if (this.filter.length > 1) this.fetchData();
      else if (this.filter.length == 0) this.fetchData();
    },

    search(event) {
      if (this.filter.length > 1) {
        if (event.keyCode == 13) {
          this.fetchData();
        }
      } else if (this.filter.length == 0) this.fetchData();
    },

    filterPage() {
      if (this.pageNo.length > 0) {
        this.currentPage = this.pageNo;
      }
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    passwordModal(itemId) {
      this.modalPassword = itemId;
      this.showPasswordModal = true;
      this.form.password = "";
    },

    closeModal() {
      this.submitted = false;
    },

    handlePassword(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.handleSubmitPassword();
    },

    checkFormPasswordValidity() {
      if (this.form.password == "") {
        this.submitted = true;
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: "Please Fill The Password Field",
        });
        return false;
      } else if (this.passwordMatch == false) {
        this.$v.$touch();
        this.$store.commit("toast/updateStatus", {
          status: this.passwordMatch,
          icon: "error",
          title: "Please Fill Correct pasword",
        });
      }
      return true;
    },

    handleSubmitPassword() {
      let validateForm = this.checkFormPasswordValidity();
      if (!validateForm) {
        return;
      } else {
        this.checkPassword();
        this.$nextTick(() => {
          this.$bvModal.hide("bvModal");
        });
        this.submitted = false;
        this.fetchData(this.currentPage);
      }
    },

    async checkPassword() {
      try {
        this.$store.commit("loader/updateStatus", true);
        let url = members.checkPassword;
        let dataAppend = new FormData();
        dataAppend.append("id", this.modalPassword);
        dataAppend.append("password", this.form.password);
        const data = await this.postRequest(url, dataAppend);
        this.passwordMatch = data.response.status;
        if (data.status) {
          this.$store.commit("toast/updateStatus", {
            status: true,
            icon: "success",
            title: data.message,
          });
        }
        if (data.response) {
          this.navigateToSuccessPage(this.modalPassword);
        }
      } catch (err) {
        console.log(err);
      }
      this.$store.commit("loader/updateStatus", false);
    },

    navigateToSuccessPage(id) {
      this.$router.push({ name: "edit-gpdata", params: { id } });
    },

    async fetchData(pagination = null) {
      this.$store.commit("loader/updateStatus", true);
      setTimeout(async () => {
      try {
        let url = members.gpMembers;
        if (this.filter) {
          url += "?search=" + this.filter + "&page=" + pagination;
        } else {
          url += "?page=" + pagination;
        }
        const data = await this.getRequest(url);
        if (data.status) {
          this.tableData = data.response;
        }
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
      }, 60000);
    },

    async fetcheditData(id) {
      this.$store.commit("loader/updateStatus", true);
      try {
        let url = members.gpMembers;
        url += `/${id}`;
        const data = await this.getRequest(url);
        if (data.status) {
          this.editTableData = [data.response];
        }
        console.log(this.editTableData);
      } catch (err) {
        this.$store.commit("toast/updateStatus", {
          status: true,
          icon: "error",
          title: err.data ? err.data.message : "Please try again!",
        });
      }
      this.$store.commit("loader/updateStatus", false);
    },
  },
  watch: {
    currentPage: {
      handler: function (v) {
        if (v != "" && v != null) {
          this.fetchData(v);
          this.currentPage = this.pageNo;
          this.currentPage = v;
        }
      },
    },
  },
  created() {
    if (this.$route.name == "edit-gpdata") {
      this.fetcheditData(this.$route.params.id);
    } else {
      this.fetchData();
    }
  },
};
