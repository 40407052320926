<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row" id="expert">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row align-items-center mb-4">
              <div class="col-md-6"></div>
            </div>
            <div class="table-responsive mb-0">
              <b-table
                striped
                bordered
                :items="editTableData"
                :per-page="0"
                :current-page="currentPage"
                :fields="editFields"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :filter-included-fields="filterOn"
                @filtered="onFiltered"
                responsive="sm"
                class="table-bordered table-hover"
                :key="key"
              >
                <template v-slot:cell(name)="row">
                  <span class="text-center">
                    <template
                      v-if="
                        (row.item.first_name != null &&
                          row.item.first_name != '') ||
                        (row.item.last_name != null && row.item.last_name != '')
                      "
                    >
                      <span class="email-word-break"
                        >{{ row.item.first_name }}
                        {{ row.item.last_name }}</span
                      >
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == 'null' ||
                        row.item.last_name == 'null'
                      "
                    >
                      <span class="email-word-break">---</span>
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == '' || row.item.last_name == ''
                      "
                    >
                      <span>---</span>
                    </template>
                    <template
                      v-else-if="
                        row.item.first_name == null ||
                        row.item.last_name == null
                      "
                    >
                      <span>---</span>
                    </template>
                    <template v-else>
                      <span>---</span>
                    </template>
                  </span>
                </template>
                <template v-slot:cell(city)="row">
                  <span v-if="row.item.city" class="text-center">{{
                    row.item.city
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(country)="row">
                  <span v-if="row.item.country" class="text-center">{{
                    row.item.country
                  }}</span>
                  <span v-else>---</span>
                </template>
                <template v-slot:cell(edit)="row">
                  <router-link
                    :to="{ name: 'edit-plans', params: { id: row.item.id } }"
                  >
                    <i class="fa fa-edit edit-i"></i>
                  </router-link>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import MixinRequest from "../../../mixins/request";
import gpDataMixin from "../../../mixins/ModuleJs/gpdata";

export default {
  mixins: [MixinRequest, gpDataMixin],
  data() {
    return {
      title: "GP DATA",
      items: [
        {
          text: "Back",
          href: "/gpdata",
        },
        {
          text: "List",
        },
      ],
    };
  },
  components: {
    Layout,
    PageHeader,
  },
};
</script>
